html,
body,
#__next {
    font-family: "Open Sans", sans-serif;
    height: 100%;
    margin: 0;
    padding: 0;
}

a,
span.linkPlaceholder {
    border: 0;
    color: #005EA8;
    cursor: pointer;
    text-decoration: none;
    text-underline: none;
}

h1, h2, h3, h4, h5, p, a {
    font-family: "Open Sans", sans-serif;
}

body.no-scroll {
    overflow: hidden;
}
